import React, { useState } from 'react';
import { getContract, getProvider } from '../contracts'; // Assuming getContract is correctly imported
import { ethers } from 'ethers';

const RebaseWizard = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Handle pausing the szBRL contract
  const handlePause = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const szBRLContract = await getContract('szBRL');
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await szBRLContract.connect(signer).pause();
      await tx.wait();
      setSuccess('szBRL contract successfully paused.');
      setCurrentStep(2);
    } catch (err) {
      setError('Error pausing szBRL contract: ' + err.message);
    }
    setLoading(false);
  };

  // Handle minting tokens to the szBRL contract address
  const handleMint = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!amount || isNaN(amount) || Number(amount) <= 0) {
        throw new Error('Please enter a valid amount.');
      }
      const zBRLContract = await getContract('zBRL');
      const szBRLContract = await getContract('szBRL'); // For getting the address
      const provider = await getProvider();
      const signer = await provider.getSigner();

      const mintAmount = ethers.parseUnits(amount, 18); // Adjust decimals as necessary
      const tx = await zBRLContract.connect(signer).mint(szBRLContract.target, mintAmount);
      await tx.wait();
      setSuccess(`Successfully minted ${amount} tokens to szBRL contract address.`);
      setCurrentStep(3);
    } catch (err) {
      setError('Error minting tokens: ' + err.message);
    }
    setLoading(false);
  };

  // Handle unpausing the szBRL contract
  const handleUnpause = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const szBRLContract = await getContract('szBRL');
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await szBRLContract.connect(signer).unpause();
      await tx.wait();
      setSuccess('szBRL contract successfully unpaused.');
      setCurrentStep(4);
    } catch (err) {
      setError('Error unpausing szBRL contract: ' + err.message);
    }
    setLoading(false);
  };

  // Handle emitting the rebase event
  const handleEmitRebaseEvent = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const szBRLContract = await getContract('szBRL');
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await szBRLContract.connect(signer).emitRebasedEvent();
      await tx.wait();
      setSuccess('Rebase event successfully emitted.');
      setCurrentStep(5); // End of wizard
    } catch (err) {
      setError('Error emitting rebase event: ' + err.message);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="title">Rebase Wizard</h1>
      <div className="box">
        {currentStep === 1 && (
          <div>
            <h2 className="subtitle">Step 1: Pause szBRL Contract</h2>
            <button
              className="button is-warning"
              onClick={handlePause}
              disabled={loading}
            >
              {loading ? 'Pausing...' : 'Pause'}
            </button>
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <h2 className="subtitle">Step 2: Mint Tokens to szBRL Contract</h2>
            <div className="field">
              <label className="label">Amount to Mint</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  min="0"
                  required
                />
              </div>
            </div>
            <button
              className="button is-primary"
              onClick={handleMint}
              disabled={loading || !amount}
            >
              {loading ? 'Minting...' : 'Mint'}
            </button>
          </div>
        )}
        {currentStep === 3 && (
          <div>
            <h2 className="subtitle">Step 3: Unpause szBRL Contract</h2>
            <button
              className="button is-success"
              onClick={handleUnpause}
              disabled={loading}
            >
              {loading ? 'Unpausing...' : 'Unpause'}
            </button>
          </div>
        )}
        {currentStep === 4 && (
          <div>
            <h2 className="subtitle">Step 4: Emit Rebase Event</h2>
            <button
              className="button is-info"
              onClick={handleEmitRebaseEvent}
              disabled={loading}
            >
              {loading ? 'Emitting Event...' : 'Emit Rebase Event'}
            </button>
          </div>
        )}
        {currentStep === 5 && (
          <div>
            <h2 className="subtitle">Rebase Complete</h2>
            <p>The rebase process has been successfully completed.</p>
          </div>
        )}
        {error && <div className="notification is-danger mt-4">{error}</div>}
        {success && <div className="notification is-success mt-4">{success}</div>}
      </div>
    </div>
  );
};

export default RebaseWizard;
