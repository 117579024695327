import React, { useState } from 'react';
import { ethers } from 'ethers';
import { getProvider, getContract } from '../contracts'; // Assuming getProvider and getContract are imported correctly

const Blocklist = ({ wallet, token }) => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [isBlocked, setIsBlocked] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleCheckBlocklist = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token);
      const result = await contract.isBlocklisted(address);
      setIsBlocked(result);
    } catch (err) {
      setError('Error checking blocklist status: ' + err.message);
    }
    setLoading(false);
  };

  const handleBlocklist = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token); // Replace with appropriate contract if needed
      const provider = await getProvider();
      const signer = await provider.getSigner();

      const tx = await contract.connect(signer).blocklistWallet(address);
      await tx.wait();
      setSuccess(`Successfully blocklisted address: ${address}`);
    } catch (err) {
      setError('Error blocklisting wallet: ' + err.message);
    }
    setLoading(false);
  };

  const handleUnblocklist = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token); // Replace with appropriate contract if needed
      const provider = await getProvider();
      const signer = await provider.getSigner();

      const tx = await contract.connect(signer).unblocklistWallet(address);
      await tx.wait();
      setSuccess(`Successfully unblocked address: ${address}`);
    } catch (err) {
      setError('Error unblocking wallet: ' + err.message);
    }
    setLoading(false);
  };

  const handleDestroyBlockedFunds = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token); // Replace with appropriate contract if needed
      const provider = await getProvider();
      const signer = await provider.getSigner();

      const destroyValue = ethers.parseUnits(amount, 18); // Adjust decimals as necessary
      const tx = await contract.connect(signer).destroyBlockedFunds(address, destroyValue);
      await tx.wait();
      setSuccess(`Successfully destroyed ${amount} units of blocked funds for address: ${address}`);
    } catch (err) {
      setError('Error destroying blocked funds: ' + err.message);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="title">Blocklist Management</h1>

      <div className="box">
        <h2 className="subtitle">Manage Wallet Blocklist</h2>
        <div className="field">
          <label className="label">Address</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="0x..."
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="buttons">
          <button className="button is-link" onClick={handleCheckBlocklist} disabled={loading || !address}>
            {loading ? 'Checking...' : 'Check Blocklist Status'}
          </button>
          <button className="button is-danger" onClick={handleBlocklist} disabled={loading || !address}>
            {loading ? 'Blocklisting...' : 'Blocklist Wallet'}
          </button>
          <button className="button is-success" onClick={handleUnblocklist} disabled={loading || !address}>
            {loading ? 'Unblocklisting...' : 'Unblocklist Wallet'}
          </button>
        </div>

        {isBlocked !== null && (
          <div className={`notification ${isBlocked ? 'is-danger' : 'is-success'} mt-4`}>
            {isBlocked ? 'This address is blocklisted.' : 'This address is not blocklisted.'}
          </div>
        )}
      </div>

      <div className="box">
        <h2 className="subtitle">Destroy Blocked Funds</h2>
        <div className="field">
          <label className="label">Amount</label>
          <div className="control">
            <input
              className="input"
              type="number"
              placeholder="Amount to destroy"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
              min="0"
            />
          </div>
        </div>
        <button className="button is-danger" onClick={handleDestroyBlockedFunds} disabled={loading || !address || !amount}>
          {loading ? 'Destroying...' : 'Destroy Blocked Funds'}
        </button>
      </div>

      {error && <div className="notification is-danger mt-4">{error}</div>}
      {success && <div className="notification is-success mt-4">{success}</div>}
    </div>
  );
};

export default Blocklist;
