import * as ethers from 'ethers';

export async function getProvider() {
  if (!window.ethereum) {
    throw new Error('MetaMask is not installed');
  }

  await window.ethereum.request({ method: 'eth_requestAccounts' });
  const provider = new ethers.BrowserProvider(window.ethereum);
  return provider;
}

export async function getContract(token) {
  const provider = await getProvider();
  const signer = await provider.getSigner();
  return new ethers.Contract(addresses[token], abis[token], signer);
}

/*
export const addresses = {
  zBRL: '0xe495c2F64D885e41e31D9420f33c041E9fB9Ff1a',
  szBRL: '0x95437F3e48D3784dccA8bAb9D0B045fB100E21c2'
}
  */

export const addresses = {
  zBRL: '0x0109A95269F3Ca4F0bD6d7cBA453dA469bb67dd9',
  szBRL: '0x955A2c5d3F357f00e50b4eB65655314c55727D72'
}

export const abis = {
  zBRL: [
    "constructor()",
    "error AccessControlBadConfirmation()",
    "error AccessControlUnauthorizedAccount(address account, bytes32 neededRole)",
    "error ERC20InsufficientAllowance(address spender, uint256 allowance, uint256 needed)",
    "error ERC20InsufficientBalance(address sender, uint256 balance, uint256 needed)",
    "error ERC20InvalidApprover(address approver)",
    "error ERC20InvalidReceiver(address receiver)",
    "error ERC20InvalidSender(address sender)",
    "error ERC20InvalidSpender(address spender)",
    "error EnforcedPause()",
    "error ExpectedPause()",
    "error InvalidInitialization()",
    "error NotInitializing()",
    "event Approval(address indexed owner, address indexed spender, uint256 value)",
    "event BlockFundsDestroyed(address indexed wallet, uint256 value)",
    "event Initialized(uint64 version)",
    "event Paused(address account)",
    "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
    "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
    "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
    "event Transfer(address indexed from, address indexed to, uint256 value)",
    "event Unpaused(address account)",
    "event WalletBlocklisted(address indexed wallet)",
    "event WalletUnblocklisted(address indexed wallet)",
    "function BLOCKLIST_ADMIN_ROLE() view returns (bytes32)",
    "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
    "function MINTER_ROLE() view returns (bytes32)",
    "function OWNER_ROLE() view returns (bytes32)",
    "function PAUSER_ROLE() view returns (bytes32)",
    "function STAKE_CONTRACT_ROLE() view returns (bytes32)",
    "function allowance(address owner, address spender) view returns (uint256)",
    "function approve(address spender, uint256 value) returns (bool)",
    "function balanceOf(address account) view returns (uint256)",
    "function batchTransfer(address[] recipients, uint256[] amounts) returns (bool)",
    "function batchTransferFrom(address[] fromAddresses, address[] toAddresses, uint256[] amounts) returns (bool)",
    "function blocklistWallet(address wallet)",
    "function burn(address from, uint256 amount)",
    "function decimals() view returns (uint8)",
    "function destroyBlockedFunds(address from, uint256 amount)",
    "function getRoleAdmin(bytes32 role) view returns (bytes32)",
    "function grantRole(bytes32 role, address account)",
    "function hasRole(bytes32 role, address account) view returns (bool)",
    "function initialize(string name_, string symbol_, uint8 decimals_)",
    "function isBlocklisted(address wallet) view returns (bool)",
    "function lockForStaking(address from, uint256 amount) returns (bool)",
    "function mint(address to, uint256 amount)",
    "function name() view returns (string)",
    "function pause()",
    "function paused() view returns (bool)",
    "function recoverErc20(address tokenAddress, uint256 amount)",
    "function recoverEth(uint256 amount)",
    "function renounceRole(bytes32 role, address callerConfirmation)",
    "function revokeRole(bytes32 role, address account)",
    "function selfBurn(uint256 amount)",
    "function setTokenDetails(string newName, string newSymbol, address szbrlTokenAddress)",
    "function supportsInterface(bytes4 interfaceId) view returns (bool)",
    "function symbol() view returns (string)",
    "function totalSupply() view returns (uint256)",
    "function transfer(address to, uint256 amount) returns (bool)",
    "function transferFrom(address from, address to, uint256 amount) returns (bool)",
    "function unblocklistWallet(address wallet)",
    "function unpause()"
  ],
  szBRL: [
    "constructor()",
    "error AccessControlBadConfirmation()",
    "error AccessControlUnauthorizedAccount(address account, bytes32 neededRole)",
    "error ERC20InsufficientAllowance(address spender, uint256 allowance, uint256 needed)",
    "error ERC20InsufficientBalance(address sender, uint256 balance, uint256 needed)",
    "error ERC20InvalidApprover(address approver)",
    "error ERC20InvalidReceiver(address receiver)",
    "error ERC20InvalidSender(address sender)",
    "error ERC20InvalidSpender(address spender)",
    "error EnforcedPause()",
    "error ExpectedPause()",
    "error InvalidInitialization()",
    "error NotInitializing()",
    "event Approval(address indexed owner, address indexed spender, uint256 value)",
    "event BlockFundsDestroyed(address indexed wallet, uint256 value)",
    "event Initialized(uint64 version)",
    "event Paused(address account)",
    "event Rebased(uint256 newTotalSupply)",
    "event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)",
    "event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)",
    "event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)",
    "event Staked(address indexed user, uint256 zBRLAmount, uint256 szBRLAmount)",
    "event Transfer(address indexed from, address indexed to, uint256 value)",
    "event Unpaused(address account)",
    "event Unstaked(address indexed user, uint256 szBRLAmount, uint256 zBRLAmount)",
    "event WalletBlocklisted(address indexed wallet)",
    "event WalletUnblocklisted(address indexed wallet)",
    "function BLOCKLIST_ADMIN_ROLE() view returns (bytes32)",
    "function DEFAULT_ADMIN_ROLE() view returns (bytes32)",
    "function MINTER_ROLE() view returns (bytes32)",
    "function OWNER_ROLE() view returns (bytes32)",
    "function PAUSER_ROLE() view returns (bytes32)",
    "function allowance(address owner, address spender) view returns (uint256)",
    "function approve(address spender, uint256 value) returns (bool)",
    "function balanceOf(address account) view returns (uint256)",
    "function batchTransfer(address[] recipients, uint256[] amounts) returns (bool)",
    "function batchTransferFrom(address[] fromAddresses, address[] toAddresses, uint256[] amounts) returns (bool)",
    "function blocklistWallet(address wallet)",
    "function decimals() view returns (uint8)",
    "function destroyBlockedFunds(address from, uint256 amount)",
    "function emitRebasedEvent()",
    "function getRoleAdmin(bytes32 role) view returns (bytes32)",
    "function grantRole(bytes32 role, address account)",
    "function hasRole(bytes32 role, address account) view returns (bool)",
    "function initialize(string name_, string symbol_, uint8 decimals_)",
    "function isBlocklisted(address wallet) view returns (bool)",
    "function name() view returns (string)",
    "function pause()",
    "function paused() view returns (bool)",
    "function recoverErc20(address tokenAddress, uint256 amount)",
    "function recoverEth(uint256 amount)",
    "function renounceRole(bytes32 role, address callerConfirmation)",
    "function revokeRole(bytes32 role, address account)",
    "function setTokenDetails(string newName, string newSymbol, address zbrlTokenAddress)",
    "function sharesOf(address account) view returns (uint256)",
    "function stake(uint256 amount)",
    "function supportsInterface(bytes4 interfaceId) view returns (bool)",
    "function symbol() view returns (string)",
    "function totalShares() view returns (uint256)",
    "function totalSupply() view returns (uint256)",
    "function transfer(address to, uint256 amount) returns (bool)",
    "function transferFrom(address from, address to, uint256 amount) returns (bool)",
    "function unblocklistWallet(address wallet)",
    "function unpause()",
    "function unstake(uint256 amount)"
  ]
}