import React, { useState, useEffect } from 'react';
import { toast } from 'bulma-toast'

function ConnectWallet({ wallet, setWallet }) {
  const [loading, setLoading] = useState(false);

  const connectWallet = async () => {
    setLoading(true);

    try {
      const accounts = await window.ethereum?.request({ method: 'eth_requestAccounts' });

      setWallet(accounts?.[0]);
      setLoading(false);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      toast({
        message: 'Error connecting wallet',
        type: 'is-danger',
        dismissible: true,
        animate: { in: 'fadeIn', out: 'fadeOut' }
      })
      setLoading(false);
    }
  };

  return wallet ? (
    <div className='navbar-item has-dropdown is-hoverable'>
      <a className='navbar-link'>
        {wallet.substr(0, 8)}...{wallet.substr(-6)}
      </a>

      <div className='navbar-dropdown'>
        <a className='navbar-item' onClick={() => setWallet(null)}>
          Disconnect
        </a>
      </div>
    </div>
  ) : (
    <div className='navbar-item'>
      <button
        className={`button is-primary ${loading ? 'is-loading' : ''}`}
        onClick={connectWallet}
      >
        Connect Wallet
      </button>
    </div>
  )
}

export default ConnectWallet;