import React, { useEffect, useState } from 'react';

import ConnectWallet from './components/ConnectWallet';
import Mint from './pages/mint';
import Home from './pages/home';
import Blocklist from './pages/blocklist';
import Roles from './pages/roles';
import Emergency from './pages/emergency';
import RebaseWizard from './pages/rebase';

function App() {
  const [token, setToken] = useState('zBRL');
  const [page, setPage] = useState('home');
  const [wallet, setWallet] = useState(null);

  return (
    <>
      <div className='navbar'>
        <div className='container'>
          <div className='navbar-brand'>
            <a className='navbar-item' onClick={() => setPage('home')}>
              {token}
            </a>
            <div className='navbar-burger' data-target='navbarExampleTransparentExample'>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className='navbar-menu'>
            <div className='navbar-start'>
            <div className='navbar-item has-dropdown is-hoverable'>
                <a className='navbar-link'>
                  Network
                </a>

                <div className='navbar-dropdown'>
                  <a className='navbar-item' onClick={() => setToken('zBRL')}>
                    zBRL
                  </a>
                  <a className='navbar-item' onClick={() => setToken('szBRL')}>
                    szBRL
                  </a>
                </div>
              </div>
              
              { token === 'zBRL' && 
                <a className='navbar-item' onClick={() => setPage('mint')}>
                  Mint/Burn
                </a>
              }

              { token === 'szBRL' && 
                <a className='navbar-item' onClick={() => setPage('rebase')}>
                  Rebase
                </a>
              }

              <a className='navbar-item' onClick={() => setPage('blocklist')}>
                Blocklist
              </a>
              <a className='navbar-item' onClick={() => setPage('emergency')}>
                Emergency
              </a>
              <a className='navbar-item' onClick={() => setPage('roles')}>
                Roles
              </a>
            </div>
            <div className='navbar-end'>
              { <ConnectWallet setWallet={setWallet} wallet={wallet} /> }
            </div>
          </div>
        </div>
      </div>

      <div className='container mt-3'>
        { page === 'home' && <Home token={token} wallet={wallet} /> }
        { page === 'mint' && token === 'zBRL' && <Mint token={token} wallet={wallet} /> }
        { page === 'rebase' && token === 'szBRL' && <RebaseWizard token={token} wallet={wallet} /> }
        { page === 'blocklist' && <Blocklist token={token} wallet={wallet} /> }
        { page === 'roles' && <Roles token={token} wallet={wallet} /> }
        { page === 'emergency' && <Emergency token={token} wallet={wallet} /> }
      </div>
    </>
  );
}

export default App;
