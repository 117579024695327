import React, { useState, useEffect } from 'react';
import { getContract } from '../contracts'; // Assuming you have this utility available for fetching contracts
import { getProvider } from '../contracts';

const Emergency = ({ token, wallet }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    checkPausedStatus();
  }, [token]);

  // Check if the contract is paused
  const checkPausedStatus = async () => {
    setError('');
    setSuccess('');
    try {
      const contract = await getContract(token);
      const paused = await contract.paused();
      setIsPaused(paused);
    } catch (err) {
      setError('Error fetching paused status: ' + err.message);
    }
  };

  // Handle pause
  const handlePause = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await contract.connect(signer).pause();
      await tx.wait();
      setSuccess('Contract successfully paused.');
      checkPausedStatus();
    } catch (err) {
      setError('Error pausing the contract: ' + err.message);
    }
    setLoading(false);
  };

  // Handle unpause
  const handleUnpause = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await contract.connect(signer).unpause();
      await tx.wait();
      setSuccess('Contract successfully unpaused.');
      checkPausedStatus();
    } catch (err) {
      setError('Error unpausing the contract: ' + err.message);
    }
    setLoading(false);
  };

  // Recover ETH
  const handleRecoverEth = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await contract.connect(signer).recoverEth();
      await tx.wait();
      setSuccess('ETH successfully recovered.');
    } catch (err) {
      setError('Error recovering ETH: ' + err.message);
    }
    setLoading(false);
  };

  // Recover ERC20 tokens
  const handleRecoverErc20 = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!tokenAddress) throw new Error('Enter a valid token address');
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const tx = await contract.connect(signer).recoverErc20(tokenAddress);
      await tx.wait();
      setSuccess(`ERC20 tokens at ${tokenAddress} successfully recovered.`);
    } catch (err) {
      setError('Error recovering ERC20 tokens: ' + err.message);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="title">Emergency Management</h1>

      <div className="box">
        <h2 className="subtitle">Pause / Unpause Contract</h2>
        <p><strong>Contract Status:</strong> {isPaused ? 'Paused' : 'Active'}</p>
        <div className="buttons mt-2">
          <button
            className="button is-warning"
            onClick={handlePause}
            disabled={loading || isPaused}
          >
            {loading && !isPaused ? 'Pausing...' : 'Pause'}
          </button>
          <button
            className="button is-primary"
            onClick={handleUnpause}
            disabled={loading || !isPaused}
          >
            {loading && isPaused ? 'Unpausing...' : 'Unpause'}
          </button>
        </div>
      </div>

      <div className="box">
        <h2 className="subtitle">Recover ETH</h2>
        <button
          className="button is-danger"
          onClick={handleRecoverEth}
          disabled={loading}
        >
          {loading ? 'Recovering ETH...' : 'Recover ETH'}
        </button>
      </div>

      <div className="box">
        <h2 className="subtitle">Recover ERC20 Tokens</h2>
        <div className="field">
          <label className="label">Token Address</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="0x..."
              value={tokenAddress}
              onChange={(e) => setTokenAddress(e.target.value)}
            />
          </div>
        </div>
        <button
          className="button is-danger"
          onClick={handleRecoverErc20}
          disabled={loading || !tokenAddress}
        >
          {loading ? 'Recovering Tokens...' : 'Recover ERC20 Tokens'}
        </button>
      </div>

      {error && <div className="notification is-danger mt-4">{error}</div>}
      {success && <div className="notification is-success mt-4">{success}</div>}
    </div>
  );
};

export default Emergency;
