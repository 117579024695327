import React, { useState } from 'react';
import { ethers } from 'ethers';
import { addresses, getContract } from '../contracts';
import AddToken from '../components/AddToken';

const Home = ({ token, wallet }) => {
  const [queryAddress, setQueryAddress] = useState('');
  const [totalSupply, setTotalSupply] = useState('');
  const [balance, setBalance] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch total supply
  const fetchTotalSupply = async () => {
    setError('');
    setLoading(true);
    try {
      const contract = await getContract(token);
      const supply = await contract.totalSupply();
      setTotalSupply(ethers.formatUnits(supply, 18)); // Adjust decimals as necessary
    } catch (err) {
      setError('Error fetching total supply: ' + err.message);
    }
    setLoading(false);
  };

  // Fetch balance of an address
  const fetchBalance = async () => {
    setError('');
    setLoading(true);
    try {
      const contract = await getContract(token);
      const bal = await contract.balanceOf(queryAddress);
      setBalance(ethers.formatUnits(bal, 18)); // Adjust decimals as necessary
    } catch (err) {
      setError('Error fetching balance: ' + err.message);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="title">Token Contract Information</h1>

      <div className="box">
        <h2 className="subtitle">Total Supply</h2>
        <div className="field">
          <label className="label">Contract Address</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="0x..."
              disabled
              value={addresses[token]}
            />
          </div>
        </div>
        <button className="button is-primary" onClick={fetchTotalSupply} disabled={loading}>
          {loading ? 'Fetching...' : 'Get Total Supply'}
        </button>
        {totalSupply && (
          <div className="notification is-info mt-4">
            <strong>Total Supply: </strong> {totalSupply}
          </div>
        )}
      </div>

      <div className="box">
        <h2 className="subtitle">Get Balance of an Address</h2>
        <div className="field">
          <label className="label">Address</label>
          <div className="control is-flex">
            <input
              className="input"
              type="text"
              placeholder="0x..."
              value={queryAddress}
              onChange={(e) => setQueryAddress(e.target.value)}
            />
            <button
              type="button"
              className="button ml-2"
              disabled={!!queryAddress}
              onClick={() => {
                setQueryAddress(wallet);
              }}
            >
              Me
            </button>
          </div>
        </div>
        <button className="button is-link" onClick={fetchBalance} disabled={!queryAddress || loading}>
          {loading ? 'Fetching...' : 'Get Balance'}
        </button>
        {balance && (
          <div className="notification is-info mt-4">
            <strong>Balance: </strong> {balance}
          </div>
        )}
      </div>

      {error && (
        <div className="notification is-danger mt-4">
          {error}
        </div>
      )}
  
      <AddToken />
    </div>
  );
};

export default Home;
