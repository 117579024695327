import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { getContract, getProvider } from '../contracts'; // Assuming this is correctly imported

const Roles = ({ token, wallet }) => {
  const [roles, setRoles] = useState({
    BLOCKLIST_ADMIN_ROLE: '',
    DEFAULT_ADMIN_ROLE: '',
    MINTER_ROLE: '',
    OWNER_ROLE: '',
    PAUSER_ROLE: ''
  });
  const [selectedRole, setSelectedRole] = useState('');
  const [roleAddress, setRoleAddress] = useState('');
  const [hasRole, setHasRole] = useState(null);
  const [roleAdmin, setRoleAdmin] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchRoles();
  }, [token]);

  // Fetch role values from the contract
  const fetchRoles = async () => {
    try {
      const contract = await getContract(token);
      const rolesData = {
        BLOCKLIST_ADMIN_ROLE: await contract.BLOCKLIST_ADMIN_ROLE(),
        DEFAULT_ADMIN_ROLE: await contract.DEFAULT_ADMIN_ROLE(),
        MINTER_ROLE: await contract.MINTER_ROLE(),
        OWNER_ROLE: await contract.OWNER_ROLE(),
        PAUSER_ROLE: await contract.PAUSER_ROLE()
      };
      setRoles(rolesData);
    } catch (err) {
      setError('Error fetching roles: ' + err.message);
    }
  };

  // Check if a given address has a role
  const handleCheckRole = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!selectedRole) throw new Error('Select a role to check');
      const contract = await getContract(token);
      const roleBytes = roles[selectedRole];
      const result = await contract.hasRole(roleBytes, roleAddress);
      setHasRole(result);
    } catch (err) {
      setError('Error checking role: ' + err.message);
    }
    setLoading(false);
  };

  // Grant a role
  const handleGrantRole = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!selectedRole) throw new Error('Select a role to grant');
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const roleBytes = roles[selectedRole];
      const tx = await contract.connect(signer).grantRole(roleBytes, roleAddress);
      await tx.wait();
      setSuccess(`Successfully granted ${selectedRole} to ${roleAddress}`);
    } catch (err) {
      setError('Error granting role: ' + err.message);
    }
    setLoading(false);
  };

  // Revoke a role
  const handleRevokeRole = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!selectedRole) throw new Error('Select a role to revoke');
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const roleBytes = roles[selectedRole];
      const tx = await contract.connect(signer).revokeRole(roleBytes, roleAddress);
      await tx.wait();
      setSuccess(`Successfully revoked ${selectedRole} from ${roleAddress}`);
    } catch (err) {
      setError('Error revoking role: ' + err.message);
    }
    setLoading(false);
  };

  // Renounce a role
  const handleRenounceRole = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!selectedRole) throw new Error('Select a role to renounce');
      const contract = await getContract(token);
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const roleBytes = roles[selectedRole];
      const tx = await contract.connect(signer).renounceRole(roleBytes, wallet);
      await tx.wait();
      setSuccess(`Successfully renounced ${selectedRole}`);
    } catch (err) {
      setError('Error renouncing role: ' + err.message);
    }
    setLoading(false);
  };

  // Get role admin
  const handleGetRoleAdmin = async () => {
    setError('');
    setSuccess('');
    setLoading(true);
    try {
      if (!selectedRole) throw new Error('Select a role to get admin');
      const contract = await getContract(token);
      const roleBytes = roles[selectedRole];
      const admin = await contract.getRoleAdmin(roleBytes);
      setRoleAdmin(admin);
    } catch (err) {
      setError('Error getting role admin: ' + err.message);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <h1 className="title">Role Management</h1>

      <div className="box">
        <h2 className="subtitle">Role Operations</h2>
        <div className="field">
          <label className="label">Select Role</label>
          <div className="control">
            <div className="select">
              <select value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                <option value="">-- Select Role --</option>
                {Object.keys(roles).map(
                  (role) =>
                    (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
        </div>

        <div className="field">
          <label className="label">Address</label>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="0x..."
              value={roleAddress}
              onChange={(e) => setRoleAddress(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons">
          <button className="button is-info" onClick={handleCheckRole} disabled={loading || !selectedRole || !roleAddress}>
            {loading ? 'Checking...' : 'Check Role'}
          </button>
          <button className="button is-primary" onClick={handleGrantRole} disabled={loading || !selectedRole || !roleAddress}>
            {loading ? 'Granting...' : 'Grant Role'}
          </button>
          <button className="button is-warning" onClick={handleRevokeRole} disabled={loading || !selectedRole || !roleAddress}>
            {loading ? 'Revoking...' : 'Revoke Role'}
          </button>
          <button className="button is-danger" onClick={handleRenounceRole} disabled={loading || !selectedRole}>
            {loading ? 'Renouncing...' : 'Renounce Role'}
          </button>
          <button className="button is-link" onClick={handleGetRoleAdmin} disabled={loading || !selectedRole}>
            {loading ? 'Fetching...' : 'Get Role Admin'}
          </button>
        </div>

        {hasRole !== null && (
          <div className={`notification ${hasRole ? 'is-success' : 'is-danger'} mt-4`}>
            {hasRole ? 'Address has the selected role.' : 'Address does not have the selected role.'}
          </div>
        )}
        {roleAdmin && (
          <div className="notification is-info mt-4">
            <strong>Role Admin:</strong> {roleAdmin}
          </div>
        )}
        {error && <div className="notification is-danger mt-4">{error}</div>}
        {success && <div className="notification is-success mt-4">{success}</div>}
      </div>
    </div>
  );
};

export default Roles;
