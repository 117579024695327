import React, { useEffect, useState } from 'react';
import { addresses } from '../contracts';

const AddToken = () => {
  const [currentNetwork, setCurrentNetwork] = useState('');
  const [tokenStatus, setTokenStatus] = useState({
    zBRL: false,
    szBRL: false,
  });

  useEffect(() => {
    const checkNetwork = async () => {
      if (window.ethereum) {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        setCurrentNetwork(chainId === '0xaa36a7' ? 'Sepolia' : (
          chainId === '0x539' ? 'Ganache' : 'Other')
        );
      } else {
        setCurrentNetwork('Not connected');
      }
    };

    const checkTokenExistence = async (address) => {
      if (window.ethereum) {
        try {
          const result = await window.ethereum.request({
            method: 'eth_accounts',
          });
          return result.includes(address);
        } catch (error) {
          console.error(error);
          return false;
        }
      }
      return false;
    };

    checkNetwork();

    // Check token existence (this is a mockup, it may require a specific call to check in practice)
    (async () => {
      const zBRLExists = await checkTokenExistence(addresses.zBRL);
      const szBRLExists = await checkTokenExistence(addresses.szBRL);
      setTokenStatus({ zBRL: zBRLExists, szBRL: szBRLExists });
    })();
  }, [addresses]);

  const switchToSepolia = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xaa36a7' }], // Sepolia chain ID
      });
      setCurrentNetwork('Sepolia');
    } catch (switchError) {
      console.error(switchError);
    }
  };

  const switchToGanache = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x539', // 1337 in decimal, commonly used by Ganache
            chainName: 'Ganache Local Network',
            rpcUrls: ['http://127.0.0.1:7545'],
            nativeCurrency: {
              name: 'Ganache',
              symbol: 'GanacheETH', // Native currency symbol
              decimals: 18,
            },
            blockExplorerUrls: null, // No block explorer for local Ganache network
          },
        ],
      });
    } catch (error) {
      console.error('Error switching to Ganache:', error);
    }
  };

  const switchNetwork = () => {
    if (window.location.protocol === 'https:') {
      switchToSepolia();
    } else {
      switchToGanache();
    }
  }
  
  const addTokenToMetaMask = async (address, symbol, decimals) => {
    try {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address,
            symbol,
            decimals,
          },
        },
      });
    } catch (error) {
      console.error('Error adding token:', error);
    }
  };

  return (
    <div className="columns">
      <div className="column">
        <div className="box">
          <h3 className="title is-4">Network</h3>
          <div className='columns'>
            <div className='column'>
              <p>Current Network: {currentNetwork}</p>
            </div>
            <div className='column has-text-right'>
              <button
                className="button is-primary mt-3"
                onClick={switchNetwork}
                disabled={(currentNetwork === 'Sepolia' && window.location.protocol === 'https:') || (process.env.NODE_ENV === 'development' && currentNetwork === 'Ganache')}
              >
                Switch to {window.location.protocol === 'https:' ? 'Sepolia' : 'Ganache'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <div className="box">
          <h3 className="title is-4">Add Tokens</h3>
          <button
            className="button is-primary mt-3"
            onClick={() => addTokenToMetaMask(addresses.zBRL, 'zBRL', 18)}
            disabled={tokenStatus.zBRL}
          >
            Add zBRL
          </button>
          <button
            className="button is-primary mt-3 ml-3"
            onClick={() => addTokenToMetaMask(addresses.szBRL, 'szBRL', 18)}
            disabled={tokenStatus.szBRL}
          >
            Add szBRL
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddToken;
