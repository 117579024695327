import * as ethers from 'ethers';
import React, { useState } from 'react';
import { toast } from 'bulma-toast';
import { getContract } from '../contracts';

const Mint = ({ token, wallet }) => {
  const [mintAddress, setMintAddress] = useState('');
  const [mintAmount, setMintAmount] = useState('');
  const [burnAddress, setBurnAddress] = useState('');
  const [burnAmount, setBurnAmount] = useState('');

  const handleMintSubmit = async(e) => {
    e.preventDefault();
    // Call the mint function here, e.g., interact with your smart contract
    console.log(`Minting ${mintAmount} tokens to address: ${mintAddress}`);

    // validate address
    if (!ethers.isAddress(mintAddress)) {
      toast({
        message: 'Invalid address',
        type: 'is-danger',
        position: 'top-right',
      })
      return;
    }

    // validate amount
    if (Number(mintAmount) <= 0) {
      toast({
        message: 'Invalid amount',
        type: 'is-danger',
        position: 'top-right',
      })
      return;
    }
    
    try {
      const contract = await getContract(token);
      const tx = await contract.mint(mintAddress, ethers.parseUnits(mintAmount, 18)); // Adjust decimals if needed
      console.log('Transaction sent: ', tx);
      await tx.wait(); // Wait for the transaction to be mined
      console.log('Transaction confirmed: ', tx);
    } catch (error) {
      console.error('Error minting tokens: ', error);
    }
  };

  const handleBurnSubmit = async(e) => {
    e.preventDefault();
    // Call the burn function here, e.g., interact with your smart contract
    console.log(`Burning ${burnAmount} tokens from address: ${burnAddress}`);

    // validate address
    if (!ethers.isAddress(burnAddress)) {
      toast({
        message: 'Invalid address',
        type: 'is-danger',
        position: 'top-right',
      })
      return;
    }

    // validate amount
    if (Number(burnAmount) <= 0) {
      toast({
        message: 'Invalid amount',
        type: 'is-danger',
        position: 'top-right',
      })
      return;
    }

    try {
      const contract = await getContract(token);
      const tx = await contract.burn(burnAddress, ethers.parseUnits(burnAmount, 18)); // Adjust decimals if needed
      console.log('Transaction sent: ', tx);
      await tx.wait(); // Wait for the transaction to be mined
      console.log('Transaction confirmed: ', tx);
    } catch (error) {
      console.error('Error burning tokens: ', error);
    }
  };

  return (
    <div className="container">
      <h1 className="title">Token Functions</h1>

      <div className="box">
        <h2 className="subtitle">Mint Tokens</h2>
        <form onSubmit={handleMintSubmit}>
          <div className="field">
            <label className="label">Address to Mint To</label>
            <div className="control is-flex">
            <input
              className="input"
              type="text"
              placeholder="0x..."
              value={mintAddress}
              onChange={(e) => setMintAddress(e.target.value)}
              required
            />
            <button
              type="button"
              className="button ml-2"
              disabled={!!mintAddress}
              onClick={() => {
                setMintAddress(wallet);
              }}
            >
              Me
            </button>
          </div>
          </div>
          <div className="field">
            <label className="label">Amount</label>
            <div className="control">
              <input
                className="input"
                type="number"
                placeholder="Amount to mint"
                value={mintAmount}
                onChange={(e) => setMintAmount(e.target.value)}
                required
                min="0"
              />
            </div>
          </div>
          <div className="control">
            <button type="submit" className="button is-primary">
              Mint
            </button>
          </div>
        </form>
      </div>

      <div className="box">
        <h2 className="subtitle">Burn Tokens</h2>
        <form onSubmit={handleBurnSubmit}>
          <div className="field">
            <label className="label">Address to Burn From</label>
            <div className="control is-flex">
              <input
                className="input"
                type="text"
                placeholder="0x..."
                value={burnAddress}
                onChange={(e) => setBurnAddress(e.target.value)}
                required
              />
              <button
                type="button"
                className="button ml-2"
                onClick={() => {
                  setBurnAddress(wallet);
                }}
                disabled={!!burnAddress}
              >
                Me
              </button>
            </div>
          </div>
          <div className="field">
            <label className="label">Amount</label>
            <div className="control">
              <input
                className="input"
                type="number"
                placeholder="Amount to burn"
                value={burnAmount}
                onChange={(e) => setBurnAmount(e.target.value)}
                required
                min="0"
              />
            </div>
          </div>
          <div className="control">
            <button type="submit" className="button is-danger">
              Burn
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Mint;
