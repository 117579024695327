import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { addresses, getProvider, getContract } from './contracts';
import ConnectWallet from './components/ConnectWallet';
import AddToken from './components/AddToken';

const Home = ({ wallet, token }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [zBRLBalance, setZBRLBalance] = useState('');
  const [szBRLBalance, setSzBRLBalance] = useState('');
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBalances();
  }, []);

  // Fetch balances for connected wallet
  const fetchBalances = async () => {
    setError('');
    setLoading(true);
    try {
      const provider = await getProvider();
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      setWalletAddress(address);

      const zBRLContract = await getContract('zBRL');
      const szBRLContract = await getContract('szBRL');

      const zBRLBalance = await zBRLContract.balanceOf(address);
      const szBRLBalance = await szBRLContract.balanceOf(address);

      setZBRLBalance(ethers.formatUnits(zBRLBalance, 18)); // Adjust decimals as necessary
      setSzBRLBalance(ethers.formatUnits(szBRLBalance, 18));
    } catch (err) {
      setError('Error fetching balances: ' + err.message);
    }
    setLoading(false);
  };

  // Stake tokens
  const handleStake = async () => {
    setError('');
    setLoading(true);

    const zBRLContract = await getContract('zBRL');
    const szBRLContract = await getContract('szBRL');

    try {
      const provider = await getProvider();
      const signer = await provider.getSigner();

      const stakeValue = ethers.parseUnits(stakeAmount, 18); // Adjust decimals as necessary

      // Approve szBRL contract to spend zBRL tokens
      const approveTx = await zBRLContract.connect(signer).approve(addresses.szBRL, stakeValue);
      await approveTx.wait();

      // Stake tokens
      const stakeTx = await szBRLContract.connect(signer).stake(stakeValue);
      await stakeTx.wait();

      await fetchBalances(); // Refresh balances
    } catch (err) {
      console.error(err)
      if (err.data) {
        const decodedError = szBRLContract.interface.parseError(err.data);
        console.log(`Transaction failed: ${decodedError?.name}`, decodedError, err);
      } else {
        setError('Error during staking: ' + err.message);
      }
    }
    setLoading(false);
  };

  // Unstake tokens
  const handleUnstake = async () => {
    setError('');
    setLoading(true);
    try {
      const szBRLContract = await getContract('szBRL');
      const provider = await getProvider();
      const signer = await provider.getSigner();

      const unstakeValue = ethers.parseUnits(unstakeAmount, 18); // Adjust decimals as necessary

      // Unstake tokens
      const unstakeTx = await szBRLContract.connect(signer).unstake(unstakeValue);
      await unstakeTx.wait();

      await fetchBalances(); // Refresh balances
    } catch (err) {
      setError('Error during unstaking: ' + err.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className='navbar'>
        <div className='container'>
          <div className='navbar-brand'>
            <a className='navbar-item'>
              ZenitCentral
            </a>
            <div className='navbar-burger' data-target='navbarExampleTransparentExample'>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className='navbar-menu'>
              <div className='navbar-end'>
                { <ConnectWallet setWallet={setWalletAddress} wallet={walletAddress} /> }
              </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h1 className="title mt-3">Welcome to ZenitCentral</h1>

        <div className="box">
          <h2 className="subtitle">Connected Wallet</h2>
          <p><strong>Address:</strong> {walletAddress || 'Not connected'}</p>
          <button className="button is-info mt-3" onClick={fetchBalances} disabled={loading}>
            {loading ? 'Refreshing...' : 'Refresh Balances'}
          </button>
          {error && <div className="notification is-danger mt-4">{error}</div>}
        </div>

        <div className="box">
          <h2 className="subtitle">Balances</h2>
          <p><strong>zBRL Balance:</strong> {zBRLBalance}</p>
          <p><strong>szBRL Balance:</strong> {szBRLBalance}</p>
        </div>

        <div className='columns'>
          <div className='column'>
            <div className="box">
              <h2 className="subtitle">Stake Tokens</h2>
              <div className="field">
                <label className="label">Amount to Stake</label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    placeholder="Enter amount"
                    value={stakeAmount}
                    onChange={(e) => setStakeAmount(e.target.value)}
                    required
                    min="0"
                  />
                </div>
              </div>
              <button className="button is-primary" onClick={handleStake} disabled={loading || !stakeAmount}>
                {loading ? 'Staking...' : 'Stake'}
              </button>
            </div>
          </div>
          <div className='column'>
            <div className="box">
              <h2 className="subtitle">Unstake Tokens</h2>
              <div className="field">
                <label className="label">Amount to Unstake</label>
                <div className="control">
                  <input
                    className="input"
                    type="number"
                    placeholder="Enter amount"
                    value={unstakeAmount}
                    onChange={(e) => setUnstakeAmount(e.target.value)}
                    required
                    min="0"
                  />
                </div>
              </div>
              <button className="button is-danger" onClick={handleUnstake} disabled={loading || !unstakeAmount}>
                {loading ? 'Unstaking...' : 'Unstake'}
              </button>
            </div>
          </div>
        </div>

        <AddToken />
      </div>
    </>
  );
};

export default Home;
